// Load jQuery
import $ from 'jquery';
window.$ = window.jQuery = $;

jQuery.event.special.touchstart = {
    setup: function( _, ns, handle ) {
        this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
    }
};
jQuery.event.special.touchmove = {
    setup: function( _, ns, handle ) {
        this.addEventListener("touchmove", handle, { passive: !ns.includes("noPreventDefault") });
    }
};
jQuery.event.special.wheel = {
    setup: function( _, ns, handle ){
        this.addEventListener("wheel", handle, { passive: true });
    }
};
jQuery.event.special.mousewheel = {
    setup: function( _, ns, handle ){
        this.addEventListener("mousewheel", handle, { passive: true });
    }
};

// load fonts
WebFont.load({
    google: { // add &display=swap to end of ONLY END font family, not every single one, other it doesnt load those fonts after
        families: ['Inter:400,500,700&display=swap']
    },
    //,
    // typekit: {
    // id: 'XXXX'
    // },
    active: function() { // helps stop flash of unstyled text
        sessionStorage.fontsLoaded = true
    }
});

//Load Libraries
require('flatpickr/dist/flatpickr.min');
require('magnific-popup/dist/jquery.magnific-popup.min');
require('select2/dist/js/select2.full.min');
require('@accessible360/accessible-slick/slick/slick.min');

//Load Modules
require('./modules/accordion');
require('./modules/animatein');
require('./modules/bannerVideo');
require('./modules/formInputs');
require('./modules/gravityForms'); // only include if using WordPress Gravity plugin
require('./modules/videoButtonReplacer');
require('./modules/videoPopup');
require('./modules/videoPlay');
require('./modules/menu');
require('./modules/modal');
require('./modules/scrollTo');
require('./modules/searchToggle');
require('./modules/shareSocial');
require('./modules/sideMenu');
require('./modules/tabs');
require('./modules/alert');
require('./modules/handlebars');
