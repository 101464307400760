$("a.video").each(function () {
    const video = $(this);
    const thisInner = video.html();

    // Set attributes
    classAttributes = `${video.attr('class') && `class="${video.attr('class')}"`}`;
    dataAttributes = `${video.data('mfp-src') && `data-mfp-src="${video.data('mfp-src')}"`}`;
    // Apply attributes
    const buttonAttributes = `${classAttributes} ${dataAttributes}`;

    // Construct and replace with button
    video.replaceWith('<button '+buttonAttributes+'>'+thisInner+'</button>');
});

$("a.video-mp4").each(function () {
    const video = $(this);
    const thisInner = video.html();

    // Set attributes
    classAttributes = `${video.attr('class') && `class="${video.attr('class')}"`}`;
    dataAttributesSrc = `${video.data('src') && `data-src="${video.data('src')}"`}`;
    dataAttributesVTTSrc = `${video.data('vtt-src') && `data-vtt-src="${video.data('vtt-src')}"`}`;
    dataAttributesVTTLabel = `${video.data('vtt-label') && `data-vtt-label="${video.data('vtt-label')}"`}`;
    dataAttributesVTTSrcLang = `${video.data('vtt-srclang') && `data-vtt-srclang="${video.data('vtt-srclang')}"`}`;
    // Apply attributes
    const buttonAttributes = `${classAttributes} ${dataAttributesSrc} ${dataAttributesVTTSrc} ${dataAttributesVTTLabel} ${dataAttributesVTTSrcLang}`;

    // Construct and replace with button
    video.replaceWith('<button '+buttonAttributes+'>'+thisInner+'</button>');
});