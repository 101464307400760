/* Radiobuttons */
(function ($) {
    $(document).on('change', '.gfield_radio input', function () {
        if ($(this).is(':checked')) {
            $(this).parent().addClass('__checked');
            $(this).parent().siblings().removeClass('__checked');
        }
    });
})(jQuery);

(function ($) {
    $('.gfield_radio input:checked')
        .parent().addClass('__checked');
})(jQuery);

/* Checkboxes */
(function ($) {
    $(document).on('change', '.gfield_checkbox input', function () {
        if ($(this).is(':checked')) {
            $(this).parent().addClass('__checked');
        } else {
            $(this).parent().removeClass('__checked');
        }
    });
})(jQuery);

(function ($) {
    $('.gfield_checkbox input:checked')
        .parent().addClass('__checked');
})(jQuery);

/* Focus state */
(function ($) {
    $(document).ready(function () {
        $('.gfield_radio input').focus(function () {
            $(this).parent().addClass('__focused');
        }).blur(function () {
            $(this).parent().removeClass('__focused');
        });
        $('.gfield_checkbox input').focus(function () {
            $(this).parent().addClass('__focused');
        }).blur(function () {
            $(this).parent().removeClass('__focused');
        });
    });
})(jQuery);

/* Consent checkbox */
(function ($) {
    $(document).on('change', '.ginput_container_consent input', function () {
        if ($(this).is(':checked')) {
            $(this).parent().addClass('__checked');
        } else {
            $(this).parent().removeClass('__checked');
        }
    });
})(jQuery);

(function ($) {
    $('.gfield_checkbox input:checked')
        .parent().addClass('__checked');
})(jQuery);

// Gravity forms select
(function ($) {
    $('.ginput_container_select select').each(function () {
        $(this).select2({
            selectionCssClass: 'select2-css',
            minimumResultsForSearch: Infinity,
        })
    });
})(jQuery);

// Gravity forms multiselect, add select2 functionality
(function ($) {
    $(function () {
        var multiselect = $('.ginput_container_multiselect select');

        multiselect.each(function () {
            var selectId = $(this).parent();
            $(this).select2({
                selectionCssClass: 'select2-css',
                dropdownParent: selectId
            })
        });
    });
})(jQuery);