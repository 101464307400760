$(".video-wrap").each(function () {
    const videoWrap = $(this);
    const video = videoWrap.find('a.video-mp4, button.video-mp4');
    const thisVideoSrc = video.data('src');
    const thisVTTSrc = video.data('vtt-src');
    const thisVTTLabel = video.data('vtt-label');
    const thisVTTSrcLang = video.data('vtt-srclang');

    video.on( "click", function() {
        const videoHTML = '<video class="video" autoplay controls>'+
                            '<source src="'+thisVideoSrc+'" type="video/mp4" />'+
                            '<track label="'+thisVTTLabel+'" kind="subtitles" srclang="'+thisVTTSrcLang+'" src="'+thisVTTSrc+'" />'+
                        '</video>'
        video.replaceWith(videoHTML);
    });
});