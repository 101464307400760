import A11yDialog from 'a11y-dialog';
document.querySelectorAll('.modalContainer').forEach(function (thisDialog) {
    const id = thisDialog.id; // make sure your modalContainer element has a unique ID
    const container = document.getElementById(id)
    const dialog = new A11yDialog(container)

    dialog.on('show', function (element, event) {
        $('body').addClass('__modal')
        // Do something on show
    })
    dialog.on('hide', function (element, event) {
        $('body').removeClass('__modal')
        // Do something on hide
    })

    // For modals that need to pop up on page load
    // document.addEventListener("DOMContentLoaded", (event) => {
        // @BE hook up to cookie
        // new A11yDialog(document.getElementById('popupOnLoad')).show();
    // });
});